import { Country } from '~/types/enums/Country';

/**
 * Checking whether the country code provided is matches any of our supported countries
 * @param cc The supposedly county code that needs to be checked
 * @returns true or false, depending on whether the country code is valid or not
 */
export const checkIfCountryIsValid = (cc: string) => {
    if (Object.values(Country).includes(cc as Country)) {
        return true;
    }

    return false;
};
